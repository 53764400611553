// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';
import Tooltip from 'bootstrap/js/dist/tooltip'

const displayWidth = window.innerWidth;
const containerWidth = document.querySelector(".section__header")
let offsetWidth = ((displayWidth - containerWidth.offsetWidth) / 2) + 12

const categoriesContainer = document.querySelector(".categories__swiper-container")
const brandsContainer = document.querySelector(".brands__swiper-container")
const specialPriceContainer = document.querySelector(".special-price__swiper-container")
const mainBannerContainer = document.querySelectorAll(".main-banner")
const bestSellerContainer = document.querySelector(".products__swiper-container")

Array.from(mainBannerContainer).forEach(el => {
  if (displayWidth >= 991) {
    el.classList.add("container")
  } else {
    el.classList.remove("container")
  }
})

if (displayWidth >= 992) {
  categoriesContainer.classList.add("container")
  brandsContainer.classList.add("container")
  specialPriceContainer.classList.add("container")
  bestSellerContainer.classList.add("container")
} else {
  categoriesContainer.classList.remove("container")
  brandsContainer.classList.remove("container")
  specialPriceContainer.classList.remove("container")
  bestSellerContainer.classList.remove("container")
}

// window.addEventListener("resize", () => {
//   const displayWidth = window.innerWidth;
//   const containerWidth = document.querySelector(".section__header")
//   offsetWidth = ((displayWidth - containerWidth.offsetWidth) / 2) + 12

//   if (displayWidth > 1024) {
//     categoriesContainer.classList.add("container")
//     brandsContainer.classList.add("container")
//     specialPriceContainer.classList.add("container")
//   } else {
//     categoriesContainer.classList.remove("container")
//     brandsContainer.classList.remove("container")
//     specialPriceContainer.classList.remove("container")
//   }
// })

const banner_swiper = new Swiper('.banner__swiper', {
  // Optional parameters
  direction: 'horizontal',
  loop: true,
  autoplay: {
    delay: 3000,
    disableOnInteraction: false,
  },

  // If we need pagination
  pagination: {
    el: '.banner__pagination',
    clickable: true,
  },

  // Navigation arrows
  navigation: {
    nextEl: '.banner__next-btn',
    prevEl: '.banner__prev-btn',
  },
});


const categories_swiper = new Swiper('.categories__swiper', {
  // Optional parameters
  direction: 'horizontal',
  loop: false,
  slidesPerView: 2.5,
  spaceBetween: 30,
  slidesOffsetAfter: offsetWidth,
  slidesOffsetBefore: offsetWidth,

  // Navigation arrows
  navigation: {
    nextEl: '.categories__next-btn',
    prevEl: '.categories__prev-btn',
  },

  // Breakpoints
  breakpoints: {
    350: {
      slidesPerView: 2.8,
      spaceBetween: 17
    },
    375: {
      slidesPerView: 3,
      spaceBetween: 17,
    },
    425: {
      slidesPerView: 3.5,
    },
    575: {
      slidesPerView: 4,
    },
    767: {
      slidesPerView: 4.3,
    },
    991: {
      slidesPerView: 5.5,
    },
    992: {
      slidesOffsetAfter: 0,
      slidesOffsetBefore: 0,
      slidesPerView: 5,
    },
    1199.98: {
      slidesOffsetAfter: 0,
      slidesOffsetBefore: 0,
      slidesPerView: 6,
      slidesPerGroup: 6,
    }
  },
});

const brands_swiper = new Swiper('.brands__swiper', {
  // Optional parameters
  direction: 'horizontal',
  loop: false,
  slidesPerView: 2.5,
  spaceBetween: 30,
  slidesOffsetAfter: offsetWidth,
  slidesOffsetBefore: offsetWidth,
  grid: {
    rows: 2,
    fill: "row"
  },

  // Navigation arrows
  navigation: {
    nextEl: '.brands__next-btn',
    prevEl: '.brands__prev-btn',
  },

  // Breakpoints
  breakpoints: {
    350: {
      slidesPerView: 2.8,
      spaceBetween: 17,
      grid: {
        rows: 2,
        fill: "row"
      },
    },
    375: {
      slidesPerView: 3,
      spaceBetween: 17,
      grid: {
        rows: 2,
        fill: "row"
      },
    },
    425: {
      slidesPerView: 3.5,
      grid: {
        rows: 2,
        fill: "row"
      },
    },
    575: {
      slidesPerView: 4,
      grid: {
        rows: 2,
        fill: "row"
      },
    },
    767: {
      slidesPerView: 4.3,
      grid: {
        rows: 2,
        fill: "row"
      },
    },
    991: {
      slidesPerView: 5.5,
      grid: {
        rows: 2,
        fill: "row"
      },
    },
    992: {
      slidesOffsetAfter: 0,
      slidesOffsetBefore: 0,
      slidesPerView: 5,
      grid: {
        rows: 2,
        fill: "row"
      },
    },
    1199.98: {
      slidesOffsetAfter: 0,
      slidesOffsetBefore: 0,
      slidesPerView: 6,
      slidesPerGroup: 6,
      grid: {
        rows: 2,
        fill: "row"
      },
    }
  }
})

const special_price_swiper = new Swiper('.special-price__swiper', {
  // Optional parameters
  direction: 'horizontal',
  loop: false,
  slidesPerView: 1.5,
  spaceBetween: 16,
  slidesOffsetAfter: offsetWidth,
  slidesOffsetBefore: offsetWidth,

  // Navigation arrows
  navigation: {
    nextEl: '.special-price__next-btn',
    prevEl: '.special-price__prev-btn',
  },

  // Breakpoints
  breakpoints: {
    575: {
      slidesPerView: 2.5,
      
    },
    768: {
      slidesPerView: 3.3,
      spaceBetween: 30,
    },
    991: {
      slidesPerView: 4,
      spaceBetween: 30,
    },
    992: {
      slidesPerView: 4,
      slidesPerGroup: 4,
      slidesOffsetAfter: 0,
      slidesOffsetBefore: 0,
      spaceBetween: 30,
    }
  }
})

// Banner Swiper
const swiper = new Swiper('.top-banner', {
  // Optional parameters
  direction: 'horizontal',
  loop: false,
  slidesPerView: 1.3,
  spaceBetween: 16,
  slidesPerGroup: 1,
  slidesOffsetAfter: offsetWidth,
  slidesOffsetBefore: offsetWidth,

  breakpoints: {
    991: {
      slidesPerView: 2,
      slidesPerGroup: 2,
      slidesOffsetAfter: 0,
      slidesOffsetBefore: 0,
      spaceBetween: 30,
    },
  }
});

const product_swiper = new Swiper('.products__swiper', {
  // Optional parameters
  direction: 'horizontal',
  loop: false,
  slidesPerView: 1.5,
  spaceBetween: 16,
  slidesOffsetAfter: offsetWidth,
  slidesOffsetBefore: offsetWidth,

  // Breakpoints
  breakpoints: {
    575: {
      slidesPerView: 2.5,
      
    },
    768: {
      slidesPerView: 3.5,
    },
  }
})

// Product Code 
// tooltipTriggerList
const producTitle = document.querySelectorAll(".products__card-title")

// Check to determine if an overflow is happening
function isOverflowing(element) {
    return element.scrollWidth > element.offsetWidth;
}

if (displayWidth > 991) {
    producTitle.forEach(el => {
        if (isOverflowing(el)) {
            new Tooltip(el)
        }
    })
}